import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import Link from "./link";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Button from "react-bootstrap/Button";

const Navigation = () => {
	const data = useStaticQuery(graphql`
		query {
			logoImg: file(relativePath: { eq: "logo.png" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
		}
	`);

	return (
		<>
			<Helmet>
				{typeof Document !== "undefined" && (
					<script
						type="text/javascript"
						innerHTML={`                   
                var MemberSpace = window.MemberSpace || {subdomain: "sarahscourses2021"};
                (function(d){
                  var s = d.createElement("script");
                  s.src = "https://cdn.memberspace.com/scripts/widgets.js";
                  var e = d.getElementsByTagName("script")[0];
                  e.parentNode.insertBefore(s,e);
                }(document));`}
					/>
				)}
			</Helmet>
			{/* <Helmet>
				{typeof Window !== "undefined" && (
					<script
						src="https://formspree.io/js/formbutton-v1.min.js"
						defer
					></script>
				)}
				{typeof Window !== "undefined" && (
					<script
						type="text/javascript"
						innerHTML={`  
       window.formbutton=window.formbutton||function(){(formbutton.q=formbutton.q||[]).push(arguments)};
    formbutton("create", {
      action: "https://formspree.io/f/xayavpwk",
       title: "Contact Us",
       fields: [
          { 
        type: "text", 
        label: "Name:", 
        name: "name",
        required: true,
        placeholder: "Name"
      },
       { 
        type: "email", 
        label: "Email:", 
        name: "_replyto",
        required: true,
        placeholder: "Email Address"
      },
         { 
        type: "text", 
        label: "Tel:", 
        name: "telephone",
        required: true,
        placeholder: "Telephone Number"
      },
     
     {
        type: "text",
        label: "More Info:",
        name: "source",
        placeholder: "How did you hear about me?",
      },
      {
        type: "text",
        label: "Subject:",
        name: "subject",
        placeholder: "Subject",
      },
     
      {
        type: "textarea",
        label: "Message:",
        name: "message",
        placeholder: "What do you want to ask me?",
      },
      { type: "submit" }      
    ],
    styles: {
      title: {
        backgroundColor: "#AC4192",
      },
      button: {
        backgroundColor: "#AC4192",
      }
    }
   });
    `}
					/>
				)}
			</Helmet> */}
			<Navbar bg="white" expand="xl" className="sticky-top">
				<Container>
					<Link to="/" className="navbar-brand my-auto">
						<GatsbyImage
							image={data.logoImg.childImageSharp.gatsbyImageData}
							style={{ maxWidth: "250px" }}
							className="s-Logo"
							layout="constrained"
							alt="Sarah Bennett Commerical Photography in Sussex Logo"
						/>
					</Link>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
							<NavDropdown title="Services" id="basic-nav-dropdown">
								<Link className="dropdown-item" to="/services/food-photography">
									Food Photography
								</Link>
								<Link
									className="dropdown-item"
									to="/services/product-photography"
								>
									Product Photography
								</Link>
								<Link
									className="dropdown-item"
									to="/services/business-branding"
								>
									Business Branding
								</Link>
								<Link
									className="dropdown-item"
									to="/services/personal-branding"
								>
									Personal Branding
								</Link>
								<Link
									className="dropdown-item"
									to="/services/creative-management"
								>
									Creative Management
								</Link>
								<Link
									className="dropdown-item"
									to="/services/photography-training"
								>
									Training
								</Link>
							</NavDropdown>
							<Link className="nav-link" to="/portfolio">
								Portfolio
							</Link>
							<Link className="nav-link" to="/reviews">
								Testimonials
							</Link>
							<Link className="nav-link" to="/about-us">
								About
							</Link>
							<Link className="nav-link" to="/blog">
								Blog
							</Link>
							<Link className="text-primary nav-link" to="/contact-us">
								Contact Us
							</Link>
						</Nav>
						{/* <Nav>
							<Nav.Link
								as={Link}
								className="text-lg-center text-start px-0 pt-3 py-lg-1"
							>
								<a href="https://sarahscourses2021.memberspace.com/member/sign_in">
									<Button
										className="btn ms-lg-5 mb-3 mb-lg-0"
										variant="outline-primary"
									>
										Login
									</Button>
								</a>
							</Nav.Link>
						</Nav> */}
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default Navigation;
