import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { MdLocationOn } from "@react-icons/all-files/md/MdLocationOn";
import { MdPhone } from "@react-icons/all-files/md/MdPhone";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";

const Footer = () => {
	const data = useStaticQuery(graphql`
		query {
			logoImg: file(relativePath: { eq: "logo.png" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
		}
	`);
	return (
		<section className="py-5 bg-white">
			<Container>
				<Row className="align-items-xl-end">
					<Col xs={12} xl={6}>
						<GatsbyImage image={data.logoImg.childImageSharp.gatsbyImageData} />
					</Col>
					<Col xs={12} xl={6} className="text-center">
						<ul className="float-xl-end p-0">
							<li style={footerLi}>
								<Link to="/services" className="text-dark pe-3">
									Services
								</Link>
							</li>
							<li style={footerLi}>
								<Link to="/reviews" className="text-dark px-3">
									Testimonials
								</Link>
							</li>
							<li style={footerLi}>
								<Link to="/portfolio" className="text-dark px-3">
									Portfolio
								</Link>
							</li>
							<li style={footerLi}>
								<Link to="/about-us" className="text-dark px-3">
									About
								</Link>
							</li>
							<li style={footerLi}>
								<Link to="/contact-us" className="text-dark ps-3">
									Contact
								</Link>
							</li>
						</ul>
						<ul className="p-0 float-xl-end">
							<li style={footerLi}>
								<a
									rel="noreferrer"
									className="text-dark pe-4 social-icon"
									href="https://www.youtube.com/channel/UCjC3FRZA57srjsyQT36XAqA?view_as=subscriber"
									target="_blank"
								>
									<FaYoutube />
								</a>
							</li>
							<li style={footerLi}>
								<a
									rel="noreferrer"
									className="text-dark px-4 social-icon"
									href="https://twitter.com/sarahbcomphoto"
									target="_blank"
								>
									<FaTwitter />
								</a>
							</li>
							<li style={footerLi}>
								<a
									rel="noreferrer"
									className="text-dark px-4 social-icon"
									href="https://www.facebook.com/sarahbennettcommercial/"
									target="_blank"
								>
									<FaFacebookSquare />
								</a>
							</li>
							<li style={footerLi}>
								<a
									rel="noreferrer"
									className="text-dark px-4 social-icon"
									href="https://www.instagram.com/sarahbennettcommercialphoto/"
									target="_blank"
								>
									<FaInstagram />
								</a>
							</li>
							<li style={footerLi}>
								<a
									rel="noreferrer"
									className="text-dark ps-4 social-icon"
									href="https://www.linkedin.com/in/sarahbennettcommercial/"
									target="_blank"
								>
									<FaLinkedin />
								</a>
							</li>
						</ul>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={12} xl={5}>
						<p className=" text-dark text-center text-lxl-end mb-3 ">
							<MdLocationOn /> 105 Hurst Road, Eastbourne, BN21 2PN
						</p>
					</Col>
					<Col xs={12} xl={4}>
						<Link
							className="text-dark email-link text-xl-end text-center"
							to="mailto:sarah@sarah-bennett.com"
						>
							<p>
								{" "}
								<MdEmail /> sarah@sarah-bennett.com
							</p>
						</Link>
					</Col>
					<Col xs={12} xl={3} className="text-xl-end text-center ">
						<Link
							className="text-dark phone-number align-items-center"
							to="tel:01323384672"
						>
							<p>
								<MdPhone className="ps-1" /> 01323 384 672
							</p>
						</Link>
					</Col>
				</Row>

				<hr className="bg-light" style={{ opacity: 1 }} />
				<Row>
					<Col className="text-center text-xl-end">
						<a
							rel="noreferrer"
							href="https://www.rjm.digital/web-design-eastbourne"
							target="_blank"
							className="text-dark"
						>
							Website designed by RJM Digital
						</a>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

const footerLi = {
	display: "inline-block",
};

export default Footer;
